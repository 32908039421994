import React from 'react';
import resume from "../../resume.png"

export default function Resume() {
  return (
    <div className="resume-cointainer">
      <h1 className="resume-h1">Resume</h1>
      <img className="resume-img" src={resume} alt="Resume"></img>
          </div>
  );
}